import React, { useState } from 'react';
import PageHeading from 'components/PageHeading';
import { Card } from '@material-tailwind/react';
import useAppContext from 'context/AppContext';
import ListUser from './components/ListUser';
import UserRole from './components/UserRole';
import Tabs from 'components/Tabs';

const TABS = [
    { key: 'users', title: 'Daftar User', content: <ListUser /> },
    { key: 'user_role', title: 'Akses User', content: <UserRole /> },
];

const ManagementUsersPage = () => {
    const [activeTab, setActiveTab] = useState('users');
    const { userDetail } = useAppContext();

    const filteredTabs = userDetail?.is_admin === '0'
        ? TABS.filter(tab => tab.key === 'users')
        : TABS;

    return (
        <>
            <PageHeading title="Manajemen User" breadcrumbs={[{ text: 'Manajemen' }]} />
            <div className="space-y-4">
                <Tabs
                    tabs={filteredTabs}
                    activeTab={activeTab}
                    onTabChange={(tabKey) => setActiveTab(tabKey)}
                />
                <Card className="min-h-[500px] p-4">
                    {TABS.find((t) => t.key === activeTab).content}
                </Card>
            </div>
        </>
    );
};

export default ManagementUsersPage;
