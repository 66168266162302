import { IconButton, Modal, Paper } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { HiX } from "react-icons/hi";

type ModalProps = {
  children?: React.ReactNode;
  onClose: () => void;
  open: boolean;
  className?: string;
  showCloseButton?: boolean;
};

export default function GlobalModal({
  children,
  className,
  onClose,
  open,
  showCloseButton = true,
}: ModalProps) {
  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={clsx("max-w-lg m-auto !rounded-lg relative top-[50%] -translate-y-[50%]", className)}>
        {showCloseButton && (
          <div className="absolute right-4 top-5 items-center ">
            <IconButton
              onClick={onClose}
            >
              <HiX className="text-xl" />
            </IconButton>
          </div>
        )}
        <div className="space-y-4">

          {children}
        </div>
      </Paper>
    </Modal>
  );
}
