export const USER_ROLE = {
    ADMIN: 1,
    ASSISTANT_DOCTOR: 2,
    DOCTOR: 3,
    PHARMACY: 6,
    CASHIER: 8
}

export const STOCK_CATEGORY = {
    DRUG: 1,
    BHP: 3,
    COSMETIC: 5,
    OTHER: 99
}

export const POLY_CLINIC = {
    GENERAL: 1,
    DENTAL: 2,
}

export const PRINT_TYPE = {
    A4: 'A4',
    THERMAL: 'THERMAL'
}