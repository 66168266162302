import axios from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useMutation } from "react-query";
import { AccessRoleInsertType } from "types/access-role-create";

const useAddAccessRole = () => {
    const signOut = useSignOut();
    const headers = useAuthHeaders();

    return useMutation(async (payload: AccessRoleInsertType) => {
        try {
            return await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/access-role`, JSON.stringify(payload), { headers });
        } catch (err) {
            console.error(err);
            if (err?.response?.status === 401) signOut();
        }
    })
}

export default useAddAccessRole;
