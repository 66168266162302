import { Navigate, useLocation } from 'react-router-dom';
import useAppContext from 'context/AppContext';
import ForbiddenPage from 'pages/ForbiddenPage';
import PageLoader from 'components/PageLoader';
import { MenuAccessResponse } from 'types/menu-access';

type MenuType = MenuAccessResponse["data"]["menu"];

const getBasePath = (path: string) => {
    const segments = path.split('/').filter((i) => i !== "");
    if (segments.length >= 2) {
        return `/${segments[0]}/${segments[1]}`;
    }
    return path;
};

const userHasPermission = (path: string, accessMenu: MenuType) => {
    const basePath = getBasePath(path);

    const hardcodedPaths = [
        { original: '/stock/new', mapped: '/stock' },
        { original: '/stock/detail', mapped: '/stock' },
        { original: '/stock/edit', mapped: '/stock' },
    ];

    const mappedPath = hardcodedPaths.find(item => item.original === basePath)?.mapped || basePath;

    return accessMenu?.find(menu => menu.path === mappedPath);
};

const ProtectedRoute = ({ children }) => {
    const { userDetail, loadingUserDetail, accessMenu } = useAppContext();
    const location = useLocation();
    const pathPermission = location.pathname;

    if (loadingUserDetail) {
        return <PageLoader />;
    }

    if (!userDetail) {
        return <Navigate to="/login" />;
    }

    if (userHasPermission(pathPermission, accessMenu)) {
        return children;
    }

    if (userDetail.is_admin === '1') {
        return children;
    }

    return <ForbiddenPage />;
};

export default ProtectedRoute;
