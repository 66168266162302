import axios, { AxiosResponse } from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useQuery } from "react-query";
import { AccessRoleDetailResponse } from "types/access-role-detail";

interface AccessRoleDetailPayload {
    access_role_id?: string;
}

interface GetDetailAccessRoleResponse extends AxiosResponse {
    data: AccessRoleDetailResponse;
}

const useDetailAccessRole = ({ access_role_id }: AccessRoleDetailPayload) => {
    const signOut = useSignOut();
    const headers = useAuthHeaders();

    return useQuery<unknown, unknown, GetDetailAccessRoleResponse>({
        queryKey: ['get-access-role-detail', access_role_id],
        queryFn: async () => {
            try {
                return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/access-role/${access_role_id}`, { headers });
            } catch (err) {
                console.error(err);
                if (err?.response?.status === 401) signOut();
                throw err;
            }
        },
        enabled: !!access_role_id,
    });
};

export default useDetailAccessRole;
