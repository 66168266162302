import {
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  IconButton,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { HiChevronDown } from "react-icons/hi2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TbChevronRight, TbX } from "react-icons/tb";
import useAppContext from "context/AppContext";
import * as IconsTb from "react-icons/tb";
import * as IconsGi from "react-icons/gi";
import { MenuAccessResponse } from "types/menu-access";

type MenuType = MenuAccessResponse["data"]["menu"];

function Sidebar({ isSidebarOpen, toggleSidebar }) {
  const { isMobile, accessMenu } = useAppContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState("");

  const handleOpen = (value) => {
    setOpen(open === value ? "" : value);

    if (!isSidebarOpen && open !== value) {
      toggleSidebar();
    }
  };

  const handleRedirect = (url: string) => {
    if (url === pathname) {
      window.location.reload();
    } else {
      navigate(url);
    }
  }

  useEffect(() => {
    if (isSidebarOpen && isMobile) {
      toggleSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderMenu = (menu: MenuType, subMenu: MenuType, typeMenu: boolean) => {
    return menu?.filter((item) => item.is_main_menu === typeMenu)
      .map((item, itemIndex) => {
        const Icon = IconsTb[item.icon_name] || IconsGi[item.icon_name];

        return (item.has_child === true) ? (
          <React.Fragment key={itemIndex}>
            <ListItem
              // selected={item.child.map((c) => c.url).includes(pathname)}
              onClick={() => handleOpen(item.title)}
              className={`p-2
                        ${isSidebarOpen ? "justify-start" : "justify-center"}`}
            >
              <ListItemPrefix>
                <Icon
                  strokeWidth={1.5}
                  size={isSidebarOpen ? 20 : 24}
                />
              </ListItemPrefix>
              {isSidebarOpen && item.title}
              {isSidebarOpen && (
                <ListItemSuffix>
                  <HiChevronDown
                    strokeWidth={1.1}
                    className={`mx-auto h-4 w-4 transition-transform ${open === item.title ? "rotate-180" : ""
                      }`}
                  />
                </ListItemSuffix>
              )}
            </ListItem>
            {open === item.title && (
              <List>
                {subMenu.filter((i) => i.parent_id === item.menu_id).map((c, cIndex) => (
                  <div
                    key={`child-${cIndex}`}
                    // to={c.path + search}
                    className="text-initial"
                    onClick={() => handleRedirect(c.path)}
                  >
                    <ListItem
                      selected={pathname === c.path}
                      className={`p-2 pl-8 ${isSidebarOpen
                        ? "justify-start"
                        : "justify-center"
                        }`}
                    >
                      {isSidebarOpen && c.title}
                    </ListItem>
                  </div>
                ))}
              </List>
            )}
          </React.Fragment>
        ) : (
          <div key={itemIndex} onClick={() => handleRedirect(item.path)} className="text-initial">
            <ListItem
              key={`item-${itemIndex}`}
              selected={pathname === item.path}
              className={`break-keep p-2 ${isSidebarOpen ? "justify-start" : "justify-center"}`}
              onClick={() => handleOpen("")}
            >
              <ListItemPrefix>
                <Icon strokeWidth={1.5} size={isSidebarOpen ? 20 : 24} />
              </ListItemPrefix>
              {isSidebarOpen && item.title}
            </ListItem>
          </div>
        );
      });
  };

  return (
    <aside
      className={`fixed inset-y-0 z-10 flex max-h-screen w-64 flex-shrink-0 transform flex-col overflow-y-auto border-r bg-white px-1 shadow-lg transition-all lg:static lg:z-auto lg:shadow-none ${!isSidebarOpen ? "-translate-x-full lg:w-20 lg:translate-x-0" : ""
        }`}
    >
      <div
        className={`flex flex-shrink-0 items-center justify-between p-2 ${!isSidebarOpen ? "lg:justify-center" : ""
          }`}
      >
        <span className="mb-4 whitespace-nowrap p-2 text-xl font-semibold uppercase leading-8 tracking-wider">
          <img
            src={isSidebarOpen ? "/sidebar_logo.png" : "/sidebar_logogram.png"}
            alt="Mitramedis"
          />
        </span>
        <button onClick={toggleSidebar} className="rounded-md p-2 lg:hidden">
          <TbX size={24} className="text-gray-600" />
        </button>
      </div>
      <div className="grow">
        <div key="main-menu" className="py-2">
          {isSidebarOpen && (
            <Typography
              variant="small"
              className="mb-2 pl-4 text-xs font-bold tracking-widest text-gray-900"
            >
              MENU
            </Typography>
          )}
          <List>
            {
              renderMenu(
                accessMenu?.filter((i) => i.parent_id === '0'),
                accessMenu?.filter((i) => i.parent_id !== '0'), //submenu
                true
              )
            }
          </List>
        </div>
        <div key="extras-menu" className="py-2" >
          {isSidebarOpen && (
            <Typography
              variant="small"
              className="mb-2 pl-4 text-xs font-bold tracking-widest text-gray-900"
            >
              LAINNYA
            </Typography>
          )}
          <List>
            {
              renderMenu(
                accessMenu?.filter((i) => i.parent_id === '0'),
                accessMenu?.filter((i) => i.parent_id !== '0'), //submenu
                false
              )
            }
            <Link to={'/logout'} className="text-initial">
              <ListItem
                selected={pathname === '/logout'}
                className={`break-keep p-2 ${isSidebarOpen ? "justify-start" : "justify-center"}`}
                onClick={() => handleOpen("")}
              >
                <ListItemPrefix>
                  <IconsTb.TbLogout strokeWidth={1.5} size={isSidebarOpen ? 20 : 24} />
                </ListItemPrefix>
                {isSidebarOpen && 'Logout'}
              </ListItem>
            </Link>
          </List>
        </div>
      </div>
      <div className="h-14 flex-shrink-0 border-t p-2 flex items-center justify-center text-gray-600 cursor-pointer sticky bottom-0 bg-white">
        {/* <Typography variant="small" className="text-center">
          Mitramedis &copy; {new Date().getFullYear()}
        </Typography> */}
        <IconButton variant="outlined" className="border-none" onClick={toggleSidebar}>
          <TbChevronRight
            className={`${isSidebarOpen
              ? "-rotate-180 transform transition-transform"
              : ""
              } text-2xl`}
          />
        </IconButton>
      </div>
    </aside>
  );
}

export default Sidebar;
