import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import moment from "moment";
import AuthProvider from "react-auth-kit";
import createStore from "react-auth-kit/createStore";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import { AppProvider } from "context/AppContext";
import { ToasterProvider } from "context/ToasterContext";

import MainLayout from "components/MainLayout";

import Error from "pages/Error";
import Home from "pages/Home";
import Login from "pages/Login";
import Logout from "pages/Logout";
import Register from "pages/Register";
import Settings from "pages/Settings";

import ForgetPassword from "pages/ForgetPassword";
import ResetPassword from "pages/ResetPassword";
import DocumentRoute from "routes/document";
import MedicalRecordRoute from "routes/medical-record";
import PatientRoute from "routes/patient";
import PaymentRoute from "routes/payment";
import ReportRoute from "routes/report";
import StockRoute from "routes/stock";
import SubscriptionRoute from "routes/subscription";

import { ThemeProvider } from '@mui/material/styles';
import muiTheme from "constants/mui-theme";
import "flowbite";
import ProtectedRoute from "middleware/ProtectedRoute";
import "moment/locale/id";
import Activation from "pages/Activation";
import HelpCenter from "pages/HelpCenter";
import CashierRoute from "routes/cashier";
import MedicalTreatmentRoute from "routes/medical-treatment";
import PharmacyRoute from "routes/pharmacy";
import QueueRoute from "routes/queue";
import ManagementUserPage from "pages/ManagementUsers";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const authStore = createStore<any>({
  authType: "cookie",
  authName: process.env.REACT_APP_SESSION_PREFIX || "",
  cookieDomain: window.location.hostname,
  debug: process.env.NODE_ENV !== "production",
  cookieSecure: process.env.NODE_ENV === "production",
})

moment.locale("id");

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<MainLayout />}>
          <Route path="/" element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
          />

          <Route
            path="/patient/*"
            element={
              <ProtectedRoute>
                <PatientRoute />
              </ProtectedRoute>
            }
          />

          <Route
            path="/medical-record/*"
            element={
              <ProtectedRoute>
                <MedicalRecordRoute />
              </ProtectedRoute>
            }
          />

          <Route
            path="/stock/*"
            element={
              <ProtectedRoute>
                <StockRoute />
              </ProtectedRoute>
            }
          />

          <Route path="/pharmacy/*" element={
            <ProtectedRoute>
              <PharmacyRoute />
            </ProtectedRoute>
          } />

          <Route
            path="/medical-treatment/*"
            element={
              <ProtectedRoute>
                <MedicalTreatmentRoute />
              </ProtectedRoute>
            }
          />

          <Route
            path="/document/*"
            element={
              <ProtectedRoute>
                <DocumentRoute />
              </ProtectedRoute>
            }
          />

          <Route
            path="/report/*"
            element={
              <ProtectedRoute>
                <ReportRoute />
              </ProtectedRoute>
            }
          />

          <Route
            path="/subscription/*"
            element={
              <ProtectedRoute>
                <SubscriptionRoute />
              </ProtectedRoute>
            }
          />

          <Route
            path="/queue/*"
            element={
              <ProtectedRoute>
                <QueueRoute />
              </ProtectedRoute>
            }
          />

          <Route
            path="/cashier/*"
            element={
              <ProtectedRoute>
                <CashierRoute />
              </ProtectedRoute>
            }
          />

          <Route
            path="/payment/*"
            element={
              <ProtectedRoute>
                <PaymentRoute />
              </ProtectedRoute>
            }
          />


          <Route
            path="/help"
            element={
              <ProtectedRoute>
                <HelpCenter />
              </ProtectedRoute>
            }
          />

          <Route
            path="/user-management"
            element={
              <ProtectedRoute>
                <ManagementUserPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />

          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<Error />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/activation" element={<Activation />} />
      </>
    )
  )

  return (
    <ToasterProvider>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider theme={muiTheme}>
            <AuthProvider store={authStore}>
              <AppProvider>
                <RouterProvider router={router} />
              </AppProvider>
            </AuthProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </ToasterProvider>
  );
}

export default App;
