import axios from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useMutation } from "react-query";

interface UpdateType {
    access_role_id: string;
}

const useUpdateAccessRole = () => {
    const signOut = useSignOut();
    const headers = useAuthHeaders();

    return useMutation(async ({ access_role_id, ...payload }: UpdateType) => {
        try {
            return await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/access-role/${access_role_id}`, JSON.stringify(payload), { headers });
        } catch (err) {
            console.error(err);
            if (err?.response?.status === 401) signOut();
        }
    })
}

export default useUpdateAccessRole;
