import { Button, Spinner } from "@material-tailwind/react";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useEditUser, useUserDetail } from "api/user";
import FormSection from "components/FormSection";
import { OPTIONS_GENDER_USER } from "constants/options";
import { Label, Select, Textarea, TextInput } from "flowbite-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { UserDetailResponse } from "types/user-detail";
import useToaster from "context/ToasterContext";
import { UserData } from "entities/model/user";
import PageLoader from "components/PageLoader";

type UserType = Partial<UserDetailResponse["data"]["user"]>;

const FormUser = () => {
  const auth = useAuthUser<UserData>();
  const toaster = useToaster();

  const user_id = auth?.userId;
  const { data } = useUserDetail({ user_id });
  const editUser = useEditUser();
  const userData = data?.data?.data?.user;

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<UserType>();

  useEffect(() => {
    if (userData) {
      setValue("full_name", userData.full_name);
      setValue("email", userData.email);
      setValue("phone_number", userData.phone_number);
      setValue("id_card_number", userData.id_card_number);
      setValue("license_number", userData.license_number);
      setValue("gender", userData.gender || "1");
      setValue("birth_date", userData.birth_date);
      setValue("address", userData.address);
      setValue("service_fee", userData.service_fee || "0");
    }
  }, [setValue, userData]);

  const submitForm = (val: UserType) => {
    if (!editUser.isLoading) {
      editUser
        .mutateAsync({ user_id, ...val })
        .then((response) => {
          const success = response?.data?.success === true;
          toaster.open({
            title: (success) ? "Berhasil" : "Gagal",
            message: (success)
              ? "Berhasil menyimpan perubahan"
              : response?.data.message,
            variant: (success) ? "success" : "error",
            autoClose: true,
          });
        })
        .catch((err) => {
          toaster.open({
            title: "Gagal",
            message: err,
            variant: "error",
            autoClose: true,
          });
        });
    }
  };

  return (
    <>
      {editUser.isLoading && (
        <PageLoader />
      )}
      <form onSubmit={handleSubmit(submitForm)}>
        <FormSection title="Profil" className="max-w-xl">
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-4">
              <Label htmlFor="full_name" value="Nama Lengkap" />
              <TextInput
                type="text"
                placeholder=""
                color={Boolean(errors?.full_name) ? "failure" : "gray"}
                helperText="Nama lengkap beserta gelar"
                {...register("full_name", {
                  required: {
                    value: true,
                    message: "Wajib diisi",
                  },
                })}
              />
            </div>
            <div className="col-span-4">
              <Label htmlFor="email" value="Email" />
              <TextInput
                type="email"
                color={Boolean(errors?.email) ? "failure" : "gray"}
                helperText={errors?.email?.message}
                {...register("email", {
                  validate: (value) => {
                    if (!value) {
                      return "Email tidak boleh kosong";
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                      return "Email tidak valid";
                    }
                    return true;
                  }
                })}
              />
            </div>
            <div className="col-span-4">
              <Label htmlFor="phone_number" value="Nomor Telepon" />
              <TextInput
                type="number"
                color={Boolean(errors?.phone_number) ? "failure" : "gray"}
                helperText={errors?.phone_number?.message}
                {...register("phone_number", {
                  required: {
                    value: true,
                    message: "Wajib diisi",
                  },
                })}
              />
            </div>
            <div className="col-span-4">
              <Label
                htmlFor="id_card_number"
                value="Nomor Induk Kependudukan (NIK)"
              />
              <TextInput
                type="number"
                color={Boolean(errors?.id_card_number) ? "failure" : "gray"}
                {...register("id_card_number", {
                  required: {
                    value: true,
                    message: "Wajib diisi",
                  },
                })}
              />
            </div>
            <div className="col-span-4">
              <Label
                htmlFor="license_number"
                value="Nomor Surat Izin Praktik (SIP)"
              />
              <TextInput
                type="text"
                color={Boolean(errors?.license_number) ? "failure" : "gray"}
                helperText={errors?.license_number?.message}
                {...register("license_number", {
                  required: {
                    value: true,
                    message: "Wajib diisi",
                  },
                })}
              />
            </div>
            <div className="col-span-4">
              <Label htmlFor="gender" value="Jenis Kelamin" />
              <Select
                defaultValue=""
                required
                color={Boolean(errors?.gender) ? "failure" : "gray"}
                helperText={errors?.gender?.message}
                {...register("gender", {
                  required: {
                    value: true,
                    message: "Wajib diisi",
                  },
                })}
              >
                <option value="" disabled>
                  Pilih jenis kelamin
                </option>
                {OPTIONS_GENDER_USER.map((item) => (
                  <option key={item.key} value={item.key}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </div>
            <div className="col-span-4">
              <Label htmlFor="birth_date" value="Tanggal Lahir" />
              <TextInput
                type="date"
                color={Boolean(errors?.birth_date) ? "failure" : "gray"}
                helperText={errors?.birth_date?.message}
                {...register("birth_date", {
                  required: {
                    value: true,
                    message: "Wajib diisi",
                  },
                })}
              />
            </div>
            <div className="col-span-4">
              <Label htmlFor="address" value="Alamat" />
              <Textarea
                color={Boolean(errors?.address) ? "failure" : "gray"}
                helperText={errors?.address?.message}
                {...register("address", {
                  required: {
                    value: true,
                    message: "Wajib diisi",
                  },
                })}
              />
            </div>
            <div className="col-span-4">
              <Label htmlFor="service_fee" value="Biaya Jasa Konsultasi Dokter" />
              <TextInput
                type="text"
                placeholder=""
                color={Boolean(errors?.service_fee) ? "failure" : "gray"}
                helperText={errors?.service_fee?.message}
                {...register("service_fee", {
                  required: {
                    value: true,
                    message: "Wajib diisi",
                  },
                })}
              />
            </div>

            <div className="col-span-4 flex justify-end">
              <Button type="submit">
                {editUser.isLoading ? (
                  <Spinner className="mx-auto block" />
                ) : (
                  "Simpan"
                )}
              </Button>
            </div>
          </div>
        </FormSection>
      </form>
    </>
  );
};

export default FormUser;
