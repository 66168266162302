import axios, { AxiosResponse } from "axios"
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useQuery } from "react-query"
import useAuthHeaders from "hooks/useAuthHeaders";
import { AccessRoleAllResponse } from "types/access-role-all";

interface GetAllAccessRoleResponse extends AxiosResponse {
    data: AccessRoleAllResponse
}

const useAllAccessRole = () => {
    const signOut = useSignOut();
    const headers = useAuthHeaders();

    return useQuery<unknown, unknown, GetAllAccessRoleResponse>({
        queryKey: ['get-all-access-role'],
        queryFn: async () => {
            try {
                return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/access-role`, { headers })
            } catch (err) {
                console.error(err);
                if (err?.response?.status === 401) signOut();
            }
        },
    })
}

export default useAllAccessRole;
