import { Label } from 'flowbite-react';
import { Button } from '@material-tailwind/react';
import Modal from 'components/Modal';
import ModalBody from 'components/ModalBody';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import { useAllMenu } from 'api/menu';
import { useDetailAccessRole } from 'api/access-role';
import { OPTIONS_USER_TYPE } from 'constants/options';

interface ViewUserProps {
    isOpen: boolean;
    onClose: () => void;
    access_role_id: string;
}

const ModalViewAccessRoleUser = ({ isOpen, onClose, access_role_id }: ViewUserProps) => {
    const { data: allMenusData, isFetching: isFetchingAllMenus } = useAllMenu();
    const { data } = useDetailAccessRole({ access_role_id });

    const userType = data?.data?.data?.access_role?.user_type;
    const selectedMenus = data?.data?.data?.access_role?.menu_id;

    const divideIntoColumns = (arr: any[], columns: number) => {
        const rows = Math.ceil(arr.length / columns);
        return Array.from({ length: columns }, (_, i) =>
            arr.slice(i * rows, i * rows + rows)
        );
    };

    const menuColumns = divideIntoColumns(allMenusData?.data?.data?.menu || [], 2);

    const isSelectedMenu = (menuId: number) => selectedMenus?.includes(menuId);

    return (
        <Modal open={isOpen} onClose={onClose}>
            <ModalHeader>Detail Akses User</ModalHeader>
            <ModalBody>
                <div className="mb-4">
                    <Label>Tipe User</Label>
                    <p>{OPTIONS_USER_TYPE.find((i) => i.key === Number(userType))?.label}</p>
                </div>
                <div className="mb-4">
                    <Label>Akses Menu</Label>
                    {isFetchingAllMenus ? (
                        <p>Loading menus...</p>
                    ) : (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2">
                            {menuColumns.map((column, columnIndex) => (
                                <div key={columnIndex} className="flex flex-col space-y-2">
                                    {column.map((menu) => (
                                        <div key={menu.menu_id} className="flex items-center space-x-2">
                                            <input
                                                type="checkbox"
                                                id={`menu-${menu.menu_id}`}
                                                value={menu.menu_id}
                                                checked={isSelectedMenu(Number(menu.menu_id))}
                                                readOnly
                                                className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                            />
                                            <Label htmlFor={`menu-${menu.menu_id}`} className="text-sm font-medium text-gray-700">
                                                {menu.title}
                                            </Label>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button variant="outlined" color="red" onClick={onClose}>Tutup</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalViewAccessRoleUser;
