import axios from "axios";
import useAuthHeaders from "hooks/useAuthHeaders";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useMutation } from "react-query";

interface DeleteAccessRolePayload {
    access_role_id: string;
}

const useDeleteAccessRole = () => {
    const signOut = useSignOut();
    const headers = useAuthHeaders();

    return useMutation(async ({ access_role_id }: DeleteAccessRolePayload) => {
        try {
            return await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/access-role/${access_role_id}`, { headers });
        } catch (err) {
            console.error(err);
            if (err?.response?.status === 401) signOut();
        }
    })
}

export default useDeleteAccessRole;
