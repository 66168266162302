import { Typography } from "@mui/material";
import React from "react";

type ModalHeaderProps = {
  children?: React.ReactNode;
};

export default function ModalHeader({ children }: ModalHeaderProps) {
  return (
    <div className="pt-6 pl-6 pr-8 pb-4">
      <Typography variant="h5">
        {children}
      </Typography>
    </div>

  );
}
