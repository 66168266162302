import axios, { AxiosResponse } from "axios"
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useQuery } from "react-query"
import useAuthHeaders from "hooks/useAuthHeaders";
import { MenuAccessResponse } from "types/menu-access";

interface GetMenuAccessResponse extends AxiosResponse {
    data: MenuAccessResponse
}

const useMenuAccess = () => {
    const signOut = useSignOut();
    const headers = useAuthHeaders();

    return useQuery<unknown, unknown, GetMenuAccessResponse>({
        queryKey: ['get-all-access'],
        queryFn: async () => {
            try {
                return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/menu/access`, { headers })
            } catch (err) {
                console.error(err);
                if (err?.response?.status === 401) signOut();
            }
        },
    })
}

export default useMenuAccess;
