import { Button, Typography } from '@material-tailwind/react';
import Modal from "components/Modal";
import ModalBody from "components/ModalBody";
import ModalFooter from "components/ModalFooter";
import ModalHeader from "components/ModalHeader";
import { USER_ROLE } from 'constants/global-variable';
import { MENU_ACCESS_DATA } from 'constants/mapping-access-data';
import { OPTIONS_GENDER_USER, OPTIONS_POLYCLINIC_UNIT, OPTIONS_USER_TYPE } from 'constants/options';
import { Label, Select, Table, TextInput } from 'flowbite-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { TbCheck, TbX } from 'react-icons/tb';
import { UserManagementInsertType } from 'types/user-management-create';

interface CreateUserProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (user: UserManagementInsertType) => void;
}

const ModalCreateUser = ({ isOpen, onClose, onSave }: CreateUserProps) => {

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<UserManagementInsertType>();

    const [selectedRole, setSelectedRole] = useState('');

    const handleAddNewUser = (val: UserManagementInsertType) => {
        onSave(val);
        reset();
        setSelectedRole('');
        onClose();
    }

    const handleClose = () => {
        setSelectedRole('');
        reset();
        onClose();
    }

    const getMenuAccess = (role: string) => {
        const access = MENU_ACCESS_DATA.find((item) => item.role === role);
        return access ? access.menus : [];
    };

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <ModalHeader>Tambah User</ModalHeader>
            <ModalBody>
                <form id="userForm" noValidate onSubmit={handleSubmit(handleAddNewUser)}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="mb-4">
                            <Label htmlFor="email" value="Email" />
                            <TextInput
                                id="email"
                                type="email"
                                defaultValue=""
                                required
                                color={Boolean(errors?.email) ? "failure" : "gray"}
                                helperText={errors?.email?.message}
                                {...register('email', {
                                    validate: (value) => {
                                        if (!value) {
                                            return "Email tidak boleh kosong";
                                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                                            return "Email tidak valid";
                                        }
                                        return true;
                                    }
                                })}
                            />
                        </div>
                        <div className="mb-4">
                            <Label htmlFor="password" value="Password" />
                            <TextInput
                                id="password"
                                type="password"
                                defaultValue=""
                                required
                                color={Boolean(errors?.password) ? "failure" : "gray"}
                                helperText={errors?.password?.message}
                                {...register('password', {
                                    required: {
                                        value: true,
                                        message: 'Wajib diisi',
                                    },
                                })}
                            />
                        </div>
                        <div className="mb-4">
                            <Label htmlFor="full_name" value="Nama Lengkap" />
                            <TextInput
                                id="full_name"
                                type="text"
                                defaultValue=""
                                required
                                color={Boolean(errors?.full_name) ? "failure" : "gray"}
                                helperText={errors?.full_name?.message}
                                {...register('full_name', {
                                    required: {
                                        value: true,
                                        message: 'Wajib diisi',
                                    },
                                })}
                            />
                        </div>
                        <div className="mb-4">
                            <Label htmlFor="phone_number" value="Nomor Telepon" />
                            <TextInput
                                id="phone_number"
                                type="number"
                                defaultValue=""
                                required
                                color={Boolean(errors?.phone_number) ? "failure" : "gray"}
                                helperText={errors?.phone_number?.message}
                                {...register('phone_number', {
                                    required: {
                                        value: true,
                                        message: 'Wajib diisi',
                                    },
                                })}
                            />
                        </div>
                        <div className="mb-4">
                            <Label htmlFor="birth_date" value="Tanggal Lahir" />
                            <TextInput
                                id="birth_date"
                                type="date"
                                defaultValue=""
                                required
                                color={Boolean(errors?.birth_date) ? "failure" : "gray"}
                                helperText={errors?.birth_date?.message}
                                {...register('birth_date', {
                                    required: {
                                        value: true,
                                        message: 'Wajib diisi',
                                    },
                                })}
                            />
                        </div>
                        <div className="mb-4">
                            <Label htmlFor="gender" value="Jenis Kelamin" />
                            <Select
                                id="gender"
                                defaultValue=""
                                required
                                color={Boolean(errors?.gender) ? "failure" : "gray"}
                                helperText={errors?.gender?.message}
                                {...register('gender', {
                                    required: {
                                        value: true,
                                        message: 'Wajib diisi',
                                    },
                                })}
                            >
                                <option value="">
                                    Pilih jenis kelamin
                                </option>
                                {OPTIONS_GENDER_USER.map((item) => (
                                    <option key={item.key} value={item.key}>
                                        {item.label}
                                    </option>
                                ))}
                            </Select>
                        </div>
                        <div className="mb-4">
                            <Label htmlFor="type" value="Role" />
                            <Select
                                id="type"
                                defaultValue=""
                                required
                                color={Boolean(errors?.type) ? "failure" : "gray"}
                                helperText={errors?.type?.message}
                                {...register('type', {
                                    required: {
                                        value: true,
                                        message: 'Wajib diisi',
                                    },
                                    onChange: (e) => {
                                        setSelectedRole(e.target.value);
                                    }
                                })}
                            >
                                <option value="">
                                    Pilih role user
                                </option>
                                {OPTIONS_USER_TYPE.map((item) => (
                                    <option key={item.key} value={item.key}>
                                        {item.label}
                                    </option>
                                ))}
                            </Select>
                        </div>
                        {Number(selectedRole) !== USER_ROLE.PHARMACY && (
                            <div className="mb-4">
                                <Label htmlFor="poly_clinic" value="Poli Klinik" />
                                <Select
                                    id="poly_clinic"
                                    defaultValue=""
                                    required
                                    color={Boolean(errors?.poly_clinic) ? "failure" : "gray"}
                                    helperText={errors?.poly_clinic?.message}
                                    {...register('poly_clinic', {
                                        required: {
                                            value: true,
                                            message: 'Wajib diisi',
                                        },
                                    })}
                                >
                                    <option value="">
                                        Pilih poli/unit
                                    </option>
                                    {OPTIONS_POLYCLINIC_UNIT.map((item) => (
                                        <option key={item.key} value={item.key}>
                                            {item.label}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        )}
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button form="userForm" type="submit">Simpan</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalCreateUser;
