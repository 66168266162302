import { Button, Typography } from "@material-tailwind/react";
import { Spinner, Tooltip } from "flowbite-react";
import { useState } from "react";
import useToaster from "context/ToasterContext";
import { Table } from "flowbite-react";
import { HiEye } from "react-icons/hi";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { useAddAccessRole, useAllAccessRole, useDeleteAccessRole } from "api/access-role";
import EmptyData from "components/EmptyData";
import { TbPlus } from "react-icons/tb";
import { useAllMenu } from "api/menu";
import ModalAddAccessRoleUser from "./access-role/ModalAddAccessRoleUser";
import { AccessRoleInsertType } from "types/access-role-create";
import ModalUpdateAccessRoleUser from "./access-role/ModalUpdateAccessRole";
import useUpdateAccessRole from "api/access-role/useUpdateAccessRole";
import { AccessRoleUpdateType } from "types/access-role-update";
import ModalConfirmation from "components/organism/ModalConfirmation";
import ModalViewAccessRoleUser from "./access-role/ModalViewAccessRoleUser";

const UserRole = () => {
    const toaster = useToaster();
    const { data, isFetching, refetch } = useAllAccessRole();
    const accessRole = data?.data?.data?.access_role;

    const addAccessRole = useAddAccessRole();
    const updateAccessRoleUser = useUpdateAccessRole();
    const deleteAccessRoleUser = useDeleteAccessRole();

    const [showAccessRole, setShowAccessRole] = useState(false);
    const [addAccessUser, setAddAccessUser] = useState(false);
    const [updateAccessUser, setUpdateAccessUser] = useState(false);
    const [accessRoleId, setAccessRoleId] = useState("");
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const { data: allMenu } = useAllMenu();

    const mapMenuIdsToNames = (menuIds) => {
        return menuIds.map(id => {
            const menu = allMenu?.data?.data?.menu.find(menu => Number(menu.menu_id) === id);
            return menu ? menu.title : '-';
        }).join(', ');
    };

    const onSelectAccessRole = (access_role_id: string) => {
        setAccessRoleId(access_role_id);
        setShowAccessRole(true);
    }

    const handleAddNewAccessRoleUser = (val: AccessRoleInsertType) => {
        addAccessRole.mutateAsync({ ...val })
            .then((response) => {
                if (response.data?.success) {
                    toaster.open({
                        title: "Berhasil",
                        message: "Berhasil menambahkan akses user",
                        variant: "success",
                        autoClose: true,
                    });
                    refetch();
                } else {
                    toaster.open({
                        title: "Gagal",
                        message: response?.data.message,
                        variant: "error",
                        autoClose: true,
                    });
                }
            })
            .catch((err) => {
                toaster.open({
                    title: "Gagal",
                    message: err,
                    variant: "error",
                    autoClose: true,
                });
            });
    }

    const onUpdateAccessRole = (access_role_id: string) => {
        setAccessRoleId(access_role_id);
        setUpdateAccessUser(true);

    }

    const handleUpdateAccessRoleUser = (accessRoleId, val: AccessRoleUpdateType) => {
        updateAccessRoleUser.mutateAsync({ access_role_id: accessRoleId, ...val })
            .then((response) => {
                if (response.data?.success) {
                    toaster.open({
                        title: "Berhasil",
                        message: "Berhasil memperbarui user",
                        variant: "success",
                        autoClose: true,
                    });
                    refetch();
                } else {
                    toaster.open({
                        title: "Gagal",
                        message: response?.data.message,
                        variant: "error",
                        autoClose: true,
                    });
                }
            })
            .catch((err) => {
                toaster.open({
                    title: "Gagal",
                    message: err,
                    variant: "error",
                    autoClose: true,
                });
            });

        setAccessRoleId(null);
    }

    const onDeleteAccessRole = (accessRoleId: string) => {
        setAccessRoleId(accessRoleId);
        setShowConfirmDelete(true);
    };

    const handleDeleteAccessRoleUser = (id: string) => {
        deleteAccessRoleUser.mutateAsync({ access_role_id: id })
            .then((response) => {
                if (response.data?.success) {
                    toaster.open({
                        title: "Berhasil",
                        message: "Berhasil menghapus access role",
                        variant: "success",
                        autoClose: true,
                    });
                    refetch();
                } else {
                    toaster.open({
                        title: "Gagal",
                        message: response?.data.message,
                        variant: "error",
                        autoClose: true,
                    });
                }
            })
            .catch((err) => {
                toaster.open({
                    title: "Gagal",
                    message: err,
                    variant: "error",
                    autoClose: true,
                });
            });;

        setAccessRoleId(null);
        setShowConfirmDelete(false);
    }

    return (
        <>
            <div className="mb-8 flex items-center justify-between gap-8">
                <div>
                    <Typography variant="h5" color="blue-gray">
                        Akses User
                    </Typography>
                    <Typography color="gray" className="mt-1 font-normal">
                        Daftar Akses User
                    </Typography>
                </div>
                <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                    <Button
                        className="flex items-center gap-3"
                        size="sm"
                        onClick={() => setAddAccessUser(true)}
                    >
                        <TbPlus
                            strokeWidth={2} className="h-4 w-4" /> Tambah Akses User
                    </Button>
                </div>
            </div>
            <div className="overflow-x-auto">
                <Table className="w-full">
                    <Table.Head>
                        <Table.HeadCell
                            className="text-md whitespace-nowrap bg-gray-100 uppercase text-gray-800 dark:text-white min-w-[200px]"
                        >
                            Tipe User
                        </Table.HeadCell>
                        <Table.HeadCell
                            className="text-md whitespace-nowrap bg-gray-100 uppercase text-gray-800 dark:text-white min-w-[200px]"
                        >
                            Akses Menu
                        </Table.HeadCell>
                        <Table.HeadCell
                            className="text-md whitespace-nowrap bg-gray-100 uppercase text-gray-800 dark:text-white min-w-[200px]"
                        >

                        </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {isFetching && (
                            <Table.Row>
                                <Table.Cell colSpan={7}>
                                    <Spinner size="lg" className="mx-auto w-full" />
                                </Table.Cell>
                            </Table.Row>
                        )}
                        {!isFetching && accessRole?.length === 0 && (
                            <Table.Cell colSpan={7}>
                                <EmptyData>Data tidak ditemukan</EmptyData>
                            </Table.Cell>
                        )}
                        {!isFetching && accessRole.map((item, itemIndex) => (
                            <Table.Row key={itemIndex} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white min-w-[200px]">
                                    {item.user_type_desc}
                                </Table.Cell>
                                <Table.Cell className="min-w-[200px]">
                                    {mapMenuIdsToNames(item.menu_id)}
                                </Table.Cell>
                                <Table.Cell className="min-w-[200px]">
                                    <div className="flex gap-1">
                                        <Tooltip content="Lihat">
                                            <Button
                                                size="sm"
                                                variant="outlined"
                                                onClick={() => onSelectAccessRole(item.access_role_id)}
                                                className="py-2"
                                            >
                                                <HiEye />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip content="Ubah">
                                            <Button
                                                size="sm"
                                                variant="filled"
                                                onClick={() => onUpdateAccessRole(item.access_role_id)}
                                                className="py-2"
                                            >
                                                <FaPencilAlt />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip content="Hapus">
                                            <Button
                                                size="sm"
                                                color="red"
                                                onClick={() => onDeleteAccessRole(item.access_role_id)}
                                                className="py-2"
                                            >
                                                <FaTrashAlt />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>

            <ModalAddAccessRoleUser
                isOpen={addAccessUser}
                onClose={() => setAddAccessUser(false)}
                onSave={handleAddNewAccessRoleUser}
            />

            <ModalViewAccessRoleUser
                isOpen={showAccessRole}
                onClose={() => setShowAccessRole(false)}
                access_role_id={accessRoleId}
            />

            <ModalUpdateAccessRoleUser
                isOpen={updateAccessUser}
                onClose={() => setUpdateAccessUser(false)}
                onUpdate={(val) => handleUpdateAccessRoleUser(accessRoleId, val)}
                access_role_id={accessRoleId}
            />

            <ModalConfirmation
                open={showConfirmDelete}
                onClose={() => setShowConfirmDelete(false)}
                type="warning"
                title="Hapus data akses user ini?"
                message="Data akses user ini akan dihapus secara permanen"
                primaryAction="Ya, Hapus"
                onPrimaryActionClick={() => handleDeleteAccessRoleUser(accessRoleId)}
                secondaryAction="Batal"
                onSecondaryActionClick={() => setShowConfirmDelete(false)}
            />
        </>
    );
};

export default UserRole;
