import { useState } from 'react';
import { Label, Select } from 'flowbite-react';
import { Button } from '@material-tailwind/react';
import { useForm } from 'react-hook-form';
import Modal from "components/Modal";
import ModalBody from "components/ModalBody";
import ModalFooter from "components/ModalFooter";
import ModalHeader from "components/ModalHeader";
import { useAllMenu } from 'api/menu';
import { OPTIONS_USER_TYPE } from 'constants/options';
import { AccessRoleInsertType } from 'types/access-role-create';

interface CreateUserProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (user: AccessRoleInsertType) => void;
}

const ModalAddAccessRoleUser = ({ isOpen, onClose, onSave }: CreateUserProps) => {
    const { data: allMenusData, isFetching: isFetchingAllMenus } = useAllMenu();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<AccessRoleInsertType>();

    const [selectedMenus, setSelectedMenus] = useState<number[]>([]);

    // useEffect(() => {
    // Initialize selectedMenus with selected state from data if available
    // setSelectedMenus(data?.selectedMenus || []);
    // }, [data]);

    const handleAddNewUser = (val: AccessRoleInsertType) => {
        const userWithMenus = {
            ...val,
            menu_id: selectedMenus
        };

        onSave(userWithMenus);
        reset();
        setSelectedMenus([]);
        onClose();
    };

    const handleClose = () => {
        setSelectedMenus([]);
        reset();
        onClose();
    };

    const handleMenuCheckboxChange = (menuId: number, parentId: string) => {
        setSelectedMenus((prevSelectedMenus) => {
            let newSelectedMenus = [...prevSelectedMenus];
            const childMenus = allMenusData?.data?.data?.menu.filter(menu => Number(menu.parent_id) === menuId).map(menu => Number(menu.menu_id));

            if (prevSelectedMenus.includes(menuId)) {
                newSelectedMenus = prevSelectedMenus.filter((id) => id !== menuId);
                if (parentId !== "0") {
                    const parentChildMenus = allMenusData?.data?.data?.menu.filter(menu => menu.parent_id === parentId).map(menu => Number(menu.menu_id));
                    const hasSelectedChild = parentChildMenus.some(id => newSelectedMenus.includes(id));
                    if (!hasSelectedChild) {
                        newSelectedMenus = newSelectedMenus.filter((id) => id !== Number(parentId));
                    }
                }
                newSelectedMenus = newSelectedMenus.filter(id => !childMenus.includes(id));
            } else {
                newSelectedMenus.push(menuId);
                if (parentId !== "0" && !newSelectedMenus.includes(Number(parentId))) {
                    newSelectedMenus.push(Number(parentId));
                }
            }
            return newSelectedMenus;
        });
    };

    const divideIntoColumns = (arr: any[], columns: number) => {
        const rows = Math.ceil(arr.length / columns);
        return Array.from({ length: columns }, (_, i) =>
            arr.slice(i * rows, i * rows + rows)
        );
    };

    const menuColumns = divideIntoColumns(allMenusData?.data?.data?.menu || [], 2);

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <ModalHeader>Tambah Akses User</ModalHeader>
            <ModalBody>
                <form id="userForm" onSubmit={handleSubmit(handleAddNewUser)}>
                    <div className="mb-4">
                        <Label htmlFor="userType">Tipe User</Label>
                        <Select
                            id="user_type"
                            defaultValue=""
                            required
                            color={Boolean(errors?.user_type) ? "failure" : "gray"}
                            helperText={errors?.user_type?.message}
                            {...register('user_type', {
                                required: {
                                    value: true,
                                    message: 'Wajib diisi',
                                },
                            })}
                        >
                            <option value="">
                                Pilih tipe user
                            </option>
                            {OPTIONS_USER_TYPE.map((item) => (
                                <option key={item.key} value={item.key}>
                                    {item.label}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div className="mb-4">
                        <Label>Akses Menu</Label>
                        {isFetchingAllMenus ? (
                            <p>Loading menus...</p>
                        ) : (
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2">
                                {menuColumns.map((column, columnIndex) => (
                                    <div key={columnIndex} className="flex flex-col space-y-2">
                                        {column.map((menu) => (
                                            <div key={menu.menu_id} className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id={`menu-${menu.menu_id}`}
                                                    value={menu.menu_id}
                                                    onChange={() => handleMenuCheckboxChange(Number(menu.menu_id), menu.parent_id)}
                                                    checked={selectedMenus.includes(Number(menu.menu_id))}
                                                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                                />
                                                <Label htmlFor={`menu-${menu.menu_id}`} className="text-sm font-medium text-gray-700">
                                                    {menu.title}
                                                </Label>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button form="userForm" type="submit">Simpan</Button>
                <Button variant="outlined" color="red" onClick={handleClose}>Batal</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalAddAccessRoleUser;
